import React, { lazy } from 'react';
import Loading from '../../components/Loading';
const DesktopCCLayout = lazy(() => import(/* webpackChunkName: "desktopCCLayout" */ '../../Layouts/DesktopCCLayout'));
import { selectConfigEnableCCV2 } from '../../selectors/auth';
import { selectFeature } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
const CCV2ConversationPage = lazy(() => import(/* webpackChunkName: "ccV2" */ '../../modules/ccV2/CCV2ConversationPage'));
const CCPage = lazy(() => import(/* webpackChunkName: "ccV1" */ '../../views/cc-mode/CCPage/CCPage'));
export const CCV2ConversationPageSwitch = React.memo(() => {
    const hasFlagInFirebase = useAppSelector(selectConfigEnableCCV2);
    const hasFlagInUserProfile = useAppSelector(selectFeature('desktop-v2'));
    if (hasFlagInUserProfile || hasFlagInFirebase) {
        return (React.createElement(DesktopCCLayout, null,
            React.createElement(CCV2ConversationPage, null)));
    }
    if (hasFlagInFirebase === undefined) {
        return React.createElement(Loading, null);
    }
    return (React.createElement(DesktopCCLayout, null,
        React.createElement(CCPage, null)));
});
